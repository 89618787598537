import React, {useState, useEffect, useCallback, FC} from "react";
import cn from 'classnames'
import {
	Connection,
	PublicKey,
	Transaction,
	clusterApiUrl,
	SystemProgram,
} from "@solana/web3.js";
import {copyToClipboard} from '../../utils/copyToClipboard';
import {ToastContainer, toast} from 'react-toastify';

import ConnectedImg from 'sources/images/connected.png'
import DisconnectedImg from 'sources/images/disconnected.png'
import AddressField from 'sources/images/addressField.png'

import BtnCopyNormal from 'sources/images/btnCopy/NormalPngFull.png'
import BtnCopyHover from 'sources/images/btnCopy/HoverPngFull.png'
import BtnCopyPressed from 'sources/images/btnCopy/PressedPngFull.png'

import BtnSendNormal from 'sources/images/btnSend/Normal.png'
import BtnSendHover from 'sources/images/btnSend/Hover.png'
import BtnSendPressed from 'sources/images/btnSend/Pressed.png'

import BtnDisconnectNormal from 'sources/images/btnDisconnect/Normal.png'
import BtnDisconnectHover from 'sources/images/btnDisconnect/Hover.png'
import BtnDisconnectPressed from 'sources/images/btnDisconnect/Pressed.png'

import BtnBackNormal from 'sources/images/btnBack/Normal.png'
import BtnBackHover from 'sources/images/btnBack/Hover.png'
import BtnBackPressed from 'sources/images/btnBack/Pressed.png'

import styles from './styles.module.scss'
import {apiAddress, webAddress} from '../../config';

type DisplayEncoding = "utf8" | "hex";
type PhantomEvent = "disconnect" | "connect" | "accountChanged";
type PhantomRequestMethod =
	| "connect"
	| "disconnect"
	| "signTransaction"
	| "signAllTransactions"
	| "signMessage";

interface ConnectOpts {
	onlyIfTrusted: boolean;
}

interface PhantomProvider {
	publicKey: PublicKey | null;
	isConnected: boolean | null;
	signTransaction: (transaction: Transaction) => Promise<Transaction>;
	signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
	signMessage: (
		message: Uint8Array | string,
		display?: DisplayEncoding
	) => Promise<any>;
	connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
	disconnect: () => Promise<void>;
	on: (event: PhantomEvent, handler: (args: any) => void) => void;
	request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
}

const getProvider = (): PhantomProvider | undefined => {
	if ("solana" in window) {
		const anyWindow: any = window;
		const provider = anyWindow.solana;
		if (provider.isPhantom) {
			return provider;
		}
	}
};

// const NETWORK = clusterApiUrl("mainnet-beta");

interface IProps {
}

export const PhantomWalletConnection: FC<IProps> = () => {
	const [provider, setProvider] = useState<PhantomProvider | undefined>(() => getProvider());
	const [logs, setLogs] = useState<string[]>([]);
	const addLog = useCallback(
		(log: string) => setLogs((logs) => [...logs, "> " + log]),
		[]
	);
	// const connection = new Connection(NETWORK);
	const [connected, setConnected] = useState<boolean>(false);
	const [publicKey, setPublicKey] = useState<PublicKey | null>(null);

	const [copyBtnImg, setCopyBtnImg] = useState(BtnCopyNormal)
	const [sendBtnImg, setSendBtnImg] = useState(BtnSendNormal)
	const [disconnectBtnImg, setDisconnectBtnImg] = useState(BtnDisconnectNormal)
	const [backBtnImg, setBackBtnImg] = useState(BtnBackNormal)

	useEffect(() => {
		if (!provider) return
		// try to eagerly connect
		provider.connect().catch((err) => {
			console.log('err', err)
			// fail silently
		});
		provider.on("connect", (publicKey: PublicKey) => {
			setPublicKey(publicKey);
			addWallet(publicKey.toBase58())
			addLog("[connect] " + publicKey?.toBase58());
		});
		provider.on("disconnect", () => {
			setPublicKey(null);
			setConnected(false);
			addLog("[disconnect] 👋");
		});
		provider.on("accountChanged", (publicKey: PublicKey | null) => {
			setPublicKey(publicKey);
			if (publicKey) {
				addLog("[accountChanged] Switched account to " + publicKey?.toBase58());
			} else {
				addLog("[accountChanged] Switched unknown account");
				provider?.connect()
					.then(() => addLog("[accountChanged] Reconnected successfully"))
					.catch((err) => {
						addLog("[accountChanged] Failed to re-connect: " + err.message);
					});
			}
		});

		return () => {
			provider?.disconnect();
		};
	}, [provider, addLog]);

	const getCookie = (value: string) => {
		const matches = document.cookie.match(new RegExp(
			"(?:^|; )" + value.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	const addWallet = async (walletAddress: string) => {
		if (!walletAddress) return
		const phantomToken = getCookie('phantomToken');
		const requestOptions: RequestInit = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': phantomToken ?? ''
			},
		};
		fetch(`${apiAddress}/Account/setSolanaWallet?walletAddress=${walletAddress}`, requestOptions)
			.then(response => response.json())
			.then(data => {
				if (data.SolanaWallet) {
					setConnected(true);
					toast.success("Wallet successfully connected.")
				} else {
					toast.error("Wallet not connected!!! Try again.")
				}
			}).catch(err => {
			addLog("[error] connect: " + JSON.stringify(err));
			toast.error("Wallet not connected!!! Try again.")
		});

		// try {
		//   const transaction = await createTransferTransaction();
		//   if (!transaction) return;
		//   let signed = await provider.signTransaction(transaction);
		//   addLog("Got signature, submitting transaction");
		//   let signature = await connection.sendRawTransaction(signed.serialize());
		//   addLog("Submitted transaction " + signature + ", awaiting confirmation");
		//   await connection.confirmTransaction(signature);
		//   addLog("Transaction " + signature + " confirmed");
		// } catch (err) {
		//   console.warn(err);
		//   addLog("[error] sendTransaction: " + JSON.stringify(err));
		// }
	};

	const deleteWallet = async () => {
		const phantomToken = getCookie('phantomToken');
		const requestOptions: RequestInit = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': phantomToken ?? ''
			},
		};
		fetch(`${apiAddress}/Account/deleteSolanaWallet`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setConnected(false)
				toast.success("Wallet successfully disconnected.")
				provider?.disconnect();
			}).catch(err => {
			addLog("[error] delete wallet: " + JSON.stringify(err));
			toast.error("Wallet not disconnected!!! Try again.")
		});
	}

	const connect = async () => {
		if (!provider) {
			const provider = getProvider();
			if (provider) {
				setProvider(provider);
			} else {
				window.open("https://phantom.app/", "_blank");
			}
		} else {
			try {
				await provider?.connect();
			} catch (err) {
				console.warn(err);
				addLog("[error] connect: " + JSON.stringify(err));
			}
		}
	}

	// const disconnect = async () => {
	// 	try {
	// 		await provider?.disconnect();
	// 	} catch (err) {
	// 		console.warn(err);
	// 		addLog("[error] disconnect: " + JSON.stringify(err));
	// 	}
	// }

	const backToProfile = () => {
		window.open(`${webAddress}/auction/settings`, "_self");
	}

	const onCopyToClipboard = async () => {
		await copyToClipboard(publicKey?.toBase58() ?? '');
	}

	return (
		<div className={styles.container}>
			<ToastContainer
				hideProgressBar
				style={{width: "350px"}}
				autoClose={5000}
				theme={'colored'}
			/>
			<div className={styles.imageBlock}>
				<img src={connected ? ConnectedImg : DisconnectedImg} alt=""/>
			</div>
			<div className={styles.connectionBlock}>
				<div className={styles.topBlock}>
					<h1 className={styles.title}>Phantom Wallet Connection</h1>
					{provider && publicKey
						?
						<div className={styles.walletBlock}>
							{connected
								? <span>Connected as</span>
								: <span className={styles.connectFailure}>Wallet not connected!!!</span>
							}
							<div className={styles.walletInfo}>
								<img src={AddressField} className={styles.addressField} alt=""/>
								<img src={copyBtnImg}
										 className={styles.copyBtn}
										 alt=""
										 onClick={() => onCopyToClipboard()}
										 onMouseOut={() => setCopyBtnImg(BtnCopyNormal)}
										 onMouseOver={() => setCopyBtnImg(BtnCopyHover)}
										 onMouseDown={() => setCopyBtnImg(BtnCopyPressed)}
										 onMouseUp={() => setCopyBtnImg(BtnCopyHover)}/>
								<div className={styles.address}>{publicKey?.toBase58() ?? ''}</div>
							</div>
						</div>
						: null}
				</div>
				<div className={styles.buttonsBlock}>
					{provider && publicKey && connected
						?
						<>
							{/*<button className={styles.btn}*/}
							{/*        onClick={onSendTransaction}*/}
							{/*        onMouseOut={() => setSendBtnImg(BtnSendNormal)}*/}
							{/*        onMouseOver={() => setSendBtnImg(BtnSendHover)}*/}
							{/*        onMouseDown={() => setSendBtnImg(BtnSendPressed)}*/}
							{/*        onMouseUp={() => setSendBtnImg(BtnSendHover)}>*/}
							{/*  <img src={sendBtnImg} alt=""/>*/}
							{/*  <span>Send wallet address</span>*/}
							{/*</button>*/}
							<button className={cn(styles.btn, styles.btnDisconnect)}
											onClick={deleteWallet}
											onMouseOut={() => setDisconnectBtnImg(BtnDisconnectNormal)}
											onMouseOver={() => setDisconnectBtnImg(BtnDisconnectHover)}
											onMouseDown={() => setDisconnectBtnImg(BtnDisconnectPressed)}
											onMouseUp={() => setDisconnectBtnImg(BtnDisconnectHover)}>
								<img src={disconnectBtnImg} alt=""/>
								<span>Disconnect</span>
							</button>
						</>
						:
						<button className={styles.btn}
										onClick={connect}
										onMouseOut={() => setSendBtnImg(BtnSendNormal)}
										onMouseOver={() => setSendBtnImg(BtnSendHover)}
										onMouseDown={() => setSendBtnImg(BtnSendPressed)}
										onMouseUp={() => setSendBtnImg(BtnSendHover)}>
							<img src={sendBtnImg} alt=""/>
							<span>Connect</span>
						</button>
					}
					<button className={styles.btn}
									onClick={backToProfile}
									onMouseOut={() => setBackBtnImg(BtnBackNormal)}
									onMouseOver={() => setBackBtnImg(BtnBackHover)}
									onMouseDown={() => setBackBtnImg(BtnBackPressed)}
									onMouseUp={() => setBackBtnImg(BtnBackHover)}>
						<img src={backBtnImg} alt=""/>
						<span>Back to profile</span>
					</button>
				</div>
			</div>
		</div>
	);
}
