import React from "react";
import {PhantomWalletConnection} from './containers/PhantomWalletConnection';
import {webAddress} from './config';

import SomniumLogo from 'sources/images/somniumLogo.png'
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <div className="App">
      <img src={SomniumLogo} alt="Somnium Space"
           onClick={() => window.open(webAddress, '_self')}/>
      <PhantomWalletConnection/>
    </div>
  );
}
